import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

import Gallery from "../components/gallery.js";
import CategoryLink from "../components/catLink.js";

const ArchPage = ({ data }) => (
  <Layout>
    <SEO title="Architektur Projekte" />
    <main>
      {data.allWordpressWpArchitecture.edges.map((post, key) => (
        <section key={key}>
        <div className="mobile">
          <h1 className="mainTitle">{post.node.title}</h1>
          <CategoryLink link={post.node.acf.category_main_link_slug} name={post.node.acf.category_main_link_name}></CategoryLink>
        </div>
        <Gallery images={post.node.acf.gallery}>
          <div className="content">
            <h1 className="mainTitle screen">{post.node.title}</h1>
            {post.node.acf.category_main_link_slug ? (
             <CategoryLink link={post.node.acf.category_main_link_slug} name={post.node.acf.category_main_link_name}></CategoryLink>
          ) : (
            ``
          )}
            <div
              dangerouslySetInnerHTML={{ __html: post.node.acf.content }}
            />
          </div>
        </Gallery>
      </section>
      ))}
    </main>
  </Layout>
);

export default ArchPage;

export const query = graphql`
  query {
    allWordpressWpArchitecture(
      filter: { categories: { elemMatch: { slug: { ne: "startpage" }, name: {ne: "Hide"} } } }, sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          slug
          title
          acf {
            content
            category_main_link_name
            category_main_link_slug
            gallery {
              localFile {
                childImageSharp {
                  # Try editing the "maxWidth" value to generate resized images.
                  fluid(maxWidth: 1800, srcSetBreakpoints: [600, 1200, 1800]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
